import { BlocksContent, Breadcrumb, Html, Image, Video } from "@global";
import {
  BreadcrumbPageContext,
  isSanityFullWidthTextBlock,
  isSanityGallery,
  isSanityHtml,
  isSanityImageTextBlock,
  isSanityImageWithMeta,
  isSanityVideo,
} from "@util/types";
import { Container, H1, H3, P, PaperContainer } from "@util/standard";
import {
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
  assets,
  colors,
  fontSizes,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Maybe, Query, SanityBlogConnection } from "@graphql-types";
import { handleSaveRecipeProduct, pageWidthMargin } from "@util/helper";

import CategoryPreview from "@shared/categoryPreview";
import GalleryCarousel from "@shared/galleryCarousel";
import Layout from "@shared/layout";
import React from "react";
import RecipePreview from "@shared/recipePreview";
import SEO from "@shared/seo";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useStore } from "@state/store";
import BlogPreview from "@components/blogs/blogPreview";
import { BlocksContentContainer } from "@components/pageComponents";

const BreadcrumbContainer = styled(Container)`
  margin-top: 130px;
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 50px;
  }
`;

const Heading = styled(Container)`
  flex-direction: column;
  height: 100%;
  width: 90%;
  margin: 80px auto auto auto;
  p {
    margin-block-start: 0px;
    margin-block-end: 10px;
  }

  .heading-text {
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .heading-text {
      flex-direction: column;
      align-items: flex-start;
      h1 {
        margin: 0;
      }
      p {
        margin: 20px 0;
      }
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 40px;
  }
`;

const ImageTextBlockContainer = styled(Container)<{
  reverse?: Maybe<boolean> | undefined;
  backgroundColour?: Maybe<string> | undefined;
}>`
  width: 90%;
  margin: 2% auto 3% auto;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  background-color: ${props => props.backgroundColour};
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: column-reverse;
  }
`;

const ContentBlocksContainer = styled(Container)<{
  reverse?: Maybe<boolean> | undefined;
}>`
  width: 45%;
  margin: ${props => (props.reverse ? "auto 5%" : "auto auto auto 5%")};
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 90%;
    margin: auto;
  }
`;

const ImageContainer = styled(Container)<{
  reverse?: Maybe<boolean> | undefined;
}>`
  width: ${props => (props.reverse ? "50%" : "45%")};
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 90%;
    margin: auto;
  }
`;

const HeartIcon = styled(Container)`
  background-color: ${colors.merlin};
  width: 38px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 20%;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    top: 27%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 28px;
    height: 26px;
  }
`;
interface QueryData extends Query {
  relatedblog: SanityBlogConnection;
}
interface Props {
  data: QueryData;
  pageContext: BreadcrumbPageContext;
}

const BlogInner = (props: Props) => {
  const { data, pageContext } = props;
  const { savedBlogs } = useStore();

  const blogInner = data.allSanityBlog.nodes[0];
  const {
    id,
    publishDate,
    category,
    content,
    title,
    relatedProductsNZ,
    relatedRecipes,
    image,
  } = blogInner;

  const { relatedblog } = data;
  let parentCategoryCrumb;
  if (category?.main?.title) {
    parentCategoryCrumb = {
      crumbLabel: category?.main?.title,
      pathname: `/blog/${category?.main?.slug?.current}`,
    };
  }
  const relatedblogFiltered = relatedblog.nodes.filter(relatedBlogItem => {
    if (relatedBlogItem == null) return false;
    return blogInner.slug?.current !== relatedBlogItem.slug?.current;
  });
  const margin = pageWidthMargin();

  return (
    <>
      <SEO seoData={blogInner.meta} slug={`blog/${blogInner.slug?.current}`} />
      <Layout>
        <Container
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          margin="50px auto"
        >
          <BreadcrumbContainer>
            <Breadcrumb
              parentCrumb={parentCategoryCrumb}
              pageContext={pageContext}
              crumbLabel={title ?? undefined}
            />
          </BreadcrumbContainer>

          <Heading>
            <HeartIcon>
              <img
                src={
                  savedBlogs?.includes(id!)
                    ? assets.favouritedIcon
                    : assets.favouriteIcon
                }
                alt="icon-button"
                width="17px"
                height="17px"
                onClick={() => handleSaveRecipeProduct(id, "blog")}
              />
            </HeartIcon>

            <Container className="heading-text">
              <H1 fontFamily="Doyle" fontSize={38}>
                {title}
              </H1>
              <P fontSize={fontSizes.p.default}>{publishDate}</P>
            </Container>
            {/* {image?.asset?.gatsbyImageData && (
              <Image imageData={image?.asset?.gatsbyImageData} />
            )} */}
          </Heading>
          {content?.blogContent?.map(content => {
            if (!content) return null;
            if (isSanityImageWithMeta(content)) {
              return (
                <Container
                  margin="2% auto 2% auto"
                  height="100%"
                  width="45%"
                  key={content?._key}
                >
                  <Image imageData={content?.asset?.gatsbyImageData} />
                </Container>
              );
            }
            if (isSanityFullWidthTextBlock(content)) {
              return (
                <BlocksContentContainer
                  applyBackground={content?.applyBackground}
                  key={content?._key}
                >
                  <BlocksContent blocks={content?.textBlock?._rawBlocks} />
                </BlocksContentContainer>
              );
            }
            if (isSanityImageTextBlock(content)) {
              return (
                <ImageTextBlockContainer
                  key={content?._key}
                  reverse={content?.reverse}
                  backgroundColour={content?.backgroundColor?.value}
                >
                  <ImageContainer reverse={content?.reverse}>
                    <Image imageData={content?.image?.asset?.gatsbyImageData} />
                  </ImageContainer>
                  <ContentBlocksContainer reverse={content?.reverse}>
                    <BlocksContent blocks={content?.text?._rawBlocks} />
                  </ContentBlocksContainer>
                </ImageTextBlockContainer>
              );
            }
            if (isSanityGallery(content)) {
              return <GalleryCarousel content={content} key={content?._key} />;
            }
            if (isSanityHtml(content)) {
              return <Html content={content} key={content?._key} />;
            }
            if (isSanityVideo(content)) {
              return <Video videoSrcURL={content.url} />;
            }
            return null;
          })}

          {relatedProductsNZ && relatedProductsNZ.length > 0 && (
            <Container width="100%" flexDirection="column" margin="50px 0 0 0">
              <Container flexDirection="column" width="80%" margin="auto">
                <Container position="relative">
                  <CategoryPreview
                    products={relatedProductsNZ}
                    width={`calc(100% + ${margin})`}
                    margin={`0 -${margin} 0 0`}
                    tabletMargin={`0 -${margin} 0 0`}
                    showTabletNav={true}
                    title="Featured Products"
                  />
                </Container>
              </Container>
            </Container>
          )}

          {relatedRecipes && relatedRecipes.length > 0 && (
            <PaperContainer
              width="100%"
              flexDirection="column"
              margin="50px 0 0 0"
            >
              <Container flexDirection="column" width="80%" margin="auto">
                <H3 margin="120px auto 0px 0px">Related Recipes</H3>
                <Container
                  flexDirection="row"
                  margin="30px 0px 100px 0px"
                  isMobileColumn
                >
                  {relatedRecipes.map(recipe => {
                    return <RecipePreview key={recipe?._key} recipe={recipe} />;
                  })}
                </Container>
              </Container>
            </PaperContainer>
          )}

          <Container flexDirection="column">
            <Container flexDirection="column" width="90%" margin="auto">
              <H3 margin="120px auto 50px 0px">Related Posts</H3>
              <Container flexDirection="row" isMobileColumn width="100%">
                {relatedblogFiltered &&
                  relatedblogFiltered.length > 0 &&
                  relatedblogFiltered.slice(0, 4).map(blog => {
                    return <BlogPreview blog={blog} />;
                  })}
              </Container>
              {/* {relatedblogFiltered && (
                <WhatsNew
                  data={relatedblogFiltered.slice(0, 5)}
                  hideTitle
                  noContainerMargin
                />
              )} */}
            </Container>
          </Container>
        </Container>
      </Layout>
    </>
  );
};

export default BlogInner;

export const query = graphql`
  query BlogInnerQuery($slug: String) {
    allSanityBlog(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...blogInnerQuery
      }
    }
    relatedblog: allSanityBlog(
      limit: 5
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        ...blogPreview
      }
    }
  }
`;
